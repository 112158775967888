'use client';
import { useAppContext } from '@/contexts/AppContext';
import { noto_sans } from '@/globalConstants';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

const BreadCrumb = ({ pathname }: { pathname: string }) => {
    // Determine if the app is in web view mode.
    const {isMobileView, isAppView} = useAppContext();

 

    // Helper function to convert strings to capital case.
    const toCapitalCase = (str: string) => {
        return str
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')
            .replace(/@/g, ' at ')
            .replace(/_/g, ' ');
    };

    // Generate breadcrumb pages from the pathname.
    const pages = pathname.split('/').filter(Boolean);
    return (
        <Breadcrumbs
            className={`text-[14px] font-[500] ${isAppView ? 'hidden' : ''}`}
            separator={
                <Image
                    src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/breadcrumbSeparator.svg`}
                    alt=">"
                    priority={true}
                    loading="eager"
                    width={24} // Use width and height directly
                    height={24}
                    style={{ width: '1.5rem', height: '1.5rem' }}
                />
            }
        >
            {[''].concat(pages).map((page, index) => {
                const isLast = index === pages.length;
                const href = index > 0 ? `/${pages.slice(0, index).join('/')}` : '/';
                const displayText = page === ''
                    ? 'Home'
                    : page === 'articles'
                        ? 'Sacred Threads'
                        : toCapitalCase(page.replace(/-[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}$/, '').replace(/-/g, ' ').replace(/_/g, ' '));

                return (
                    <Link
                        key={page}
                        href={href}
                        className={`${isLast ? 'text-black' : 'text-[#8F8B8B]'} ${noto_sans.className} no-underline truncate inline-block max-w-[10rem] md:max-w-full sm:overflow-visible`}
                    >
                        {displayText.replaceAll(' ', '-').replaceAll('%20','-')}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default React.memo(BreadCrumb);
