// Importing necessary fonts from Google Fonts via 'next/font/google'
import {
    Inter,
    Noto_Sans,
    Noto_Serif,
    Ubuntu,
    Roboto,
    Great_Vibes,
} from 'next/font/google';

// Importing shared types from 'globalTypes'
import { FeatureDescriptions, Months, Rashi, Testimonial } from './globalTypes';

// Configuring revalidation for incremental static regeneration
export const revalidate = { revalidate: 3600 };

// Configurations for various Google Fonts
export const inter = Inter({
    subsets: ['latin'],
    variable: '--font-inter',
});

export const noto_sans = Noto_Sans({
    display: 'swap',
    style: 'normal',
    variable: '--font-sans',
    subsets: ['latin'],
});

export const noto_serif = Noto_Serif({
    display: 'swap',
    style: 'normal',
    variable: '--font-serif',
    subsets: ['latin'],
});

export const ubuntu = Ubuntu({
    display: 'swap',
    style: 'normal',
    variable: '--font-ubuntu',
    subsets: ['latin'],
    weight: ['400', '500', '700'],
});

export const roboto = Roboto({
    display: 'swap',
    style: 'normal',
    variable: '--font-robot',
    subsets: ['latin'],
    weight: '400',
});

export const great_vibes = Great_Vibes({
    display: 'swap',
    style: 'normal',
    subsets: ['latin'],
    variable: '--font-good-vibes',
    weight: '400',
});

// Descriptions of features displayed on the demo page
export const DEMO_DESCRIPTIONS: FeatureDescriptions[] = [
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/CalendarCheck.svg`,
        heading: 'Daily Calendar',
        descriptions: [
            'Stay informed about important dates with our easy-to-use Hindu calendar',
            'Find auspicious & inauspicious times during the day',
            'Set reminders for good and bad muhurta',
        ],
        alt: 'Calendar Icon',
    },
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/SunHorizon.svg`,
        heading: 'Panchanga',
        descriptions: [
            'Find Tithi, Vaara, Nakshatra, Yoga, and Karana',
            'View Panchanga for past and future dates',
        ],
        alt: 'Sun Set Icon',
    },
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/HourglassMedium.svg`,
        heading: 'Shubh Muhurta',
        descriptions: [
            'Align your actions with favorable celestial moments',
            'Find auspicious & inauspicious times during the day',
            'Find auspicious days for starting new projects, ceremonies, and special occasions',
        ],
        alt: 'Hourglass Icon',
    },
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/lamp.svg`,
        heading: 'Festivals and Observances',
        descriptions: [
            'Find dates for festivals and observances like Amavasya, Ekadasi, and more',
            'Get timely reminders to prepare for special days',
            'Learn about the significance of each special day',
        ],
        alt: 'Lamp Icon',
    },
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/book.svg`,
        heading: 'Learn More',
        descriptions: [
            'Broaden your understanding of the Hindu calendar system',
            'Learn about Panchanga and its elements, and insights on special days',
        ],
        alt: 'Book Icon',
    },
    {
        icon: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/PrarthnaIcon.svg`,
        heading: 'Prarthana',
        descriptions: [
            'Find prayers for your ista-devata - along with their meanings.',
            'Daily 5-minute prayer is now very easy with a customized daily prayer list.',
        ],
        alt: 'Prarthana Icon',
    },
];

// List of testimonials for the app
export const TESTIMONIALS: Testimonial[] = [
    {
        author: 'Vivek79 Yadav',
        review:
            'I discovered this app through Reddit, and wow, this app is amazing. I don’t have any problems with Dharmayana yet, so that’s good. I also love the aesthetics of the app.',
        stars: '5',
        date: 'January 15, 2024',
    },
    {
        author: 'Ramkumar Durgam',
        review:
            'Excellent app with a very clean UX and accurate information. Must have for anyone who follows the Hindu calendar.',
        stars: '5',
        date: 'January 4, 2024',
    },
    {
        author: 'Niranjan Joshi',
        review:
            'Very nice and accurate tool. Awaiting Festival module to be launched to provide the details of what the festivals are about.',
        stars: '5',
        date: 'January 27, 2024',
    },
    {
        author: 'Anupama Rao',
        review: 'A great one-stop app for instant info about auspicious days and dates.',
        stars: '5',
        date: 'January 2, 2024',
    },
    {
        author: 'Varma Alluri',
        review: 'Very useful app with Panchanga information at your fingertips.',
        stars: '5',
        date: 'January 4, 2024',
    },
];

// Screenshots of the app for showcasing
export const APP_SCREEN_SHOTS: string[] = [
    "App's Home page demo screenshot",
    "App's Panchanga page demo screenshot",
    "App's 'Good Day For' search feature screenshot",
    "App's Festival page demo screenshot",
    "App's Home demo screenshot",
];

// Days of the week mapping
export const daysOfWeek: { [day: string]: string } = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
};

// Empty months data structure for festivals
export const MONTHS: Months = {
    JANUARY: [],
    FEBRUARY: [],
    MARCH: [],
    APRIL: [],
    MAY: [],
    JUNE: [],
    JULY: [],
    AUGUST: [],
    SEPTEMBER: [],
    OCTOBER: [],
    NOVEMBER: [],
    DECEMBER: [],
};

// Default location settings
export const DEFAULT_LOCATION = {
    lat: 23.41667,
    long: 75.5,
    city: 'Ujjain',
};
// Rashis (Zodiac signs) with their respective images and banners
export const RASHIES: Rashi[] = [
    {
        vedic_name: 'Mesha',
        english_name: 'Aries',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/aries-mesha.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/aries.png`
    },
    {
        vedic_name: 'Vrishabha',
        english_name: 'Taurus',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/taurus-vrishabha.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/taurus.png`

    },
    {
        vedic_name: 'Mithuna',
        english_name: 'Gemini',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/gemini-mithuna.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/gemini.png`

    },
    {
        vedic_name: 'Karka',
        english_name: 'Cancer',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/cancer-karka.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/cancer.png`
    },
    {
        vedic_name: 'Simha',
        english_name: 'Leo',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/leo-simha.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/leo.png`
    },
    {
        vedic_name: 'Kanya',
        english_name: 'Virgo',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/virgo-kanya.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/virgo.png`,
    },
    {
        vedic_name: 'Tula',
        english_name: 'Libra',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/libra-tula.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/libra.png`,
    },
    {
        vedic_name: 'Vrischika',
        english_name: 'Scorpio',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/scorpio-vrischika.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/scorpio.png`,
    },
    {
        vedic_name: 'Dhanu',
        english_name: 'Sagittarius',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/sagittarius-dhanu.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/sagittarius.png`,
    },
    {
        vedic_name: 'Makara',
        english_name: 'Capricorn',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/capricorn-makara.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/capricorn.png`,
    },
    {
        vedic_name: 'Kumbha',
        english_name: 'Aquarius',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/aquarius-kumbha.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/aquarius.png`,
    },
    {
        vedic_name: 'Meena',
        english_name: 'Pisces',
        image_url: `${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/pisces-meena.svg`,
        banner_url:`https://d161fa2zahtt3z.cloudfront.net/rashi/large/pisces.png`,
    },
]

// Types of Prarthana collections for categorization
export const TYPES_OF_PRARTHANA_COLLECTION = [
    {
        type: 'deities',
        by_line: 'Find Prarthana for Devta',
        banner_url: '/prarthana-by-devta-banner.png',
        redirection_url: '/deities'
    },
    {
        type: 'festivals',
        by_line: 'Find Prarthana for Festival',
        banner_url: '/prarthana-by-festival-banner.png',
        redirection_url: '/prarthana/festival'
    },
    {
        type: 'purposes',
        by_line: 'Find Prarthana for Purpose',
        banner_url: '/prarthana-by-purpose-banner.png',
        redirection_url: '/prarthana/purpose'

    }
]

export const OPEN_GRAPH_IMAGE_PATH =
    process.env.NEXT_PUBLIC_OPEN_GRAPH_IMAGE_PATH === 'production'
        ? 'https://dharmayana.in/opengraph.jpg'
        : 'https://web.stage.dharmayana.in/opengraph.jpg';

// Open Graph images list with configurations for different platforms
export const OPEN_GRAPH_IMAGE_LIST = [
    {
        url: OPEN_GRAPH_IMAGE_PATH,
        width: 1200,
        height: 630, // Recommended by Facebook
    },
    {
        url: OPEN_GRAPH_IMAGE_PATH,
        width: 1200,
        height: 1200, // Square image for LinkedIn
    },
    {
        url: OPEN_GRAPH_IMAGE_PATH,
        width: 1024,
        height: 512, // Twitter Summary Card with Large Image
    },
    {
        url: OPEN_GRAPH_IMAGE_PATH,
        width: 800,
        height: 600, // Custom size
    },
    {
        url: OPEN_GRAPH_IMAGE_PATH,
        width: 1800,
        height: 1600,
        alt: 'Dharmayana Banner', // Alt text for accessibility
    },
    {
        url: OPEN_GRAPH_IMAGE_PATH,
        width: 1920,
        height: 1080, // High resolution for retina displays
    },
];

// Function to fetch custom Open Graph image configurations
export const fetchOpenGrapImageList = (image_url:string)=>{
    return [
        {
            url: image_url,
            width: 1200,
            height: 630, // Recommended by Facebook
        },
        {
            url: image_url,
            width: 1200,
            height: 1200, // Square image for LinkedIn
        },
        {
            url: image_url,
            width: 1024,
            height: 512, // Twitter Summary Card with Large Image
        },
        {
            url: image_url,
            width: 800,
            height: 600, // Custom size
        },
        {
            url: image_url,
            width: 1800,
            height: 1600,
            alt: 'Dharmayana Banner', // Alt text for accessibility
        },
        {
            url: image_url,
            width: 1920,
            height: 1080, // High resolution for retina displays
        },
    ]
}