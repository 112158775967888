'use client'
import Image from 'next/image'
import { noto_sans } from '@/globalConstants';
import Devider from './Divider';
import { useEffect, useState } from 'react';
import { usePathname } from 'next/dist/client/components/navigation';
import Link from 'next/dist/client/link';

const Footer = () => {
    const BASE_CLASS = 'footer'
    const [isWebView, setIsWebView] = useState(false);
    const pathname = usePathname();
    const displayType = (pathname.split("/")[1] === 'learn-more' || pathname.split("/")[1] === 'learn-more-mobile') ? 'hidden' : 'flex';
    useEffect(() => {
        const userAgent = navigator.userAgent || '';

        // Check if user agent is for Flutter WebView
        const isAppWebView = /AppWebView/i.test(userAgent);

        setIsWebView(isAppWebView);
    }, []);

    return (
        <footer id="contact" className={`${BASE_CLASS}__footer   bg-[#05574E] pt-[2rem] lg:pt-[4rem] pb-[1.45rem]   ${noto_sans.className} ${displayType} justify-center ${isWebView ? 'hidden' : ''}  `}>
            <div className='footer-conatiner grid items-center  gap-[1.5rem] lg:gap-[2rem] w-[95%] lg:w-[90%]     2xl:w-[70%] '>
                <div className={`${BASE_CLASS}__footer__logo__download-now  flex flex-col gap-[48px]    lg:flex-row justify-between  `}>
                    <div className='flex flex-col gap-[2rem] '>
                        <div className={`${BASE_CLASS}__footer__logo `}>
                            <Link href="/" className='flex justify-center lg:justify-start items-baseline'>
                                <Image

                                    className="logo-flower-img  h-[48px] w-[27px] mt-[1rem]"
                                    src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/dharmayana-flower.svg`} alt="Dharmāyana Logo"
                                    height={1}
                                    width={1}
                                />
                                <Image

                                    className="logo-title-img h-[48px] w-[277px]  "
                                    src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/dharmayana.svg`} alt="Dharmāyana Brand Name"
                                    height={1}
                                    width={1}
                                />
                            </Link>
                        </div>
                        <div className={`${BASE_CLASS}__download-now `}>
                            <div className={`download-nudge__app-links  grid gap-3`}>

                                <div className='flex justify-center lg:justify-start  gap-1'>
                                    <a
                                        href={process.env.NEXT_PUBLIC_KOCHAVA_LINK_ANDROID}
                                        target="_blank"

                                    ><Image

                                            priority={true}
                                            fetchPriority='high'
                                            loading='eager'
                                            id="play-store-header"
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/Play Store Badge.webp`}
                                            height={25}
                                            width={121}
                                            alt="Download the app for Android"
                                            className='h-[auto] hover:scale-110 transition delay-150 duration-300 ease-in-out '
                                        /></a>

                                    <a
                                        href={process.env.NEXT_PUBLIC_KOCHAVA_LINK_IOS}
                                        target="_blank"
                                    ><Image

                                            loading='eager'
                                            id="app-store-footer"
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/iOS Badge.webp`}
                                            height={1}
                                            width={1}
                                            alt="Download the app for IOS"
                                            className='h-[36px] w-[125px]  hover:scale-110 transition delay-150 duration-300 ease-in-out  '
                                        /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-12 sm:justify-around px-10 lg:px-0'>
                        <div className={`features-nav-bar flex flex-col  gap-[8px] md:items-center font-bold  ${noto_sans.className} tracking-[0.05rem] text-white text-[1rem]`}>

                            <ul className='flex flex-col  gap-[8px] '>
                                <li className='text-[#84AB88]'>
                                    FEATURES
                                </li>
                                {(BASE_CLASS === "footer") && <li className={`${BASE_CLASS}__nav-links__link cursor-pointer 
                                                hover:text-[#ebe793] hover:text-shadow 
                                                transition delay-150 duration-200 
                                                ease-in-out           
                                             `}>
                                    <Link href="/panchanga/">Panchanga</Link>
                                </li>}
                                {(BASE_CLASS === "footer") && <li className={`${BASE_CLASS}__nav-links__link cursor-pointer 
                                                hover:text-[#ebe793] hover:text-shadow 
                                                transition delay-150 duration-200 
                                                ease-in-out  
                                                   
                                             `}>
                                    <Link href="/prarthana">Prarthana</Link>
                                </li>}
                                {(BASE_CLASS === "footer") && <li className={`${BASE_CLASS}__nav-links__link cursor-pointer 
                                                hover:text-[#ebe793] hover:text-shadow 
                                                transition delay-150 duration-200 
                                                ease-in-out           
                                             `}>
                                    <Link href="/festivals/">Festivals</Link>
                                </li>}
                                {(BASE_CLASS === "footer") && <li className={`${BASE_CLASS}__nav-links__link cursor-pointer 
                                                hover:text-[#ebe793] hover:text-shadow 
                                                transition delay-150 duration-200 
                                                ease-in-out        
                                                flex 
                                                flex-no-wrap   
                                             `}>
                                    <Link href="/shubh-dina">
                                        <span className='text-nowrap '> Shubh Dina</span>
                                    </Link>
                                </li>}
                                {(BASE_CLASS === "footer") && <li className={`${BASE_CLASS}__nav-links__link cursor-pointer 
                                                hover:text-[#ebe793] hover:text-shadow 
                                                transition delay-150 duration-200 
                                                ease-in-out           
                                             `}>
                                    <Link href="/rashifal">Rashifal</Link>
                                </li>}
                            </ul>
                        </div>
                        <div className={`resources-nav-bar flex flex-col  gap-[8px] md:items-center  font-bold  ${noto_sans.className} tracking-[0.05rem] text-white text-[1rem]`}>
                            <ul className='flex flex-col gap-[12px] '>
                                <li className='text-[#84AB88]'>
                                    RESOURCES
                                </li>
                                <li className={`${BASE_CLASS}__nav-links__link cursor-pointer 
                                                    hover:text-[#ebe793] hover:text-shadow 
                                                    transition delay-150 duration-200 
                                                    ease-in-out
                                                    `}>
                                    <Link href="/learn-more-web">Learn More</Link>
                                </li>
                                <li className={`${BASE_CLASS}__nav-links__link cursor-pointer 
                                                    hover:text-[#ebe793] hover:text-shadow 
                                                    transition delay-150 duration-200 
                                                    ease-in-out
                                                    `}>
                                    <Link href="/articles">Articles</Link>
                                </li>
                                <li className={`${BASE_CLASS}__nav-links__link 
                                            cursor-pointer hover:text-[#ebe793] 
                                            hover:text-shadow transition delay-150 
                                            duration-200 ease-in-out
                                       
                                            `}>
                                    <Link href="/apps">Download App</Link>
                                </li>
                            </ul>
                        </div>
                        <div className={`features-nav-bar flex flex-col  gap-[8px] md:items-center  font-bold  ${noto_sans.className} tracking-[0.05rem] text-white text-[1rem]`}>
                            <ul className='flex flex-col  gap-[12px] '>
                                <li className='text-[#84AB88]'>COMPANY</li>
                                {(BASE_CLASS === "footer") && <li className={`${BASE_CLASS}__nav-links__link 
                                            cursor-pointer hover:text-[#ebe793] 
                                            hover:text-shadow transition delay-150 
                                            duration-200 ease-in-out
                                       
                                            `}>
                                    <Link href="/policy">Privacy Policy</Link>
                                </li>}
                                {<li className={`${BASE_CLASS}__nav-links__link cursor-pointer 
                                                                       hover:text-[#ebe793] hover:text-shadow 
                                                    transition delay-150 duration-200 
                                                    ease-in-out                                    
                                                     `}>
                                    <Link href={pathname + '#contact'}>Contact Us</Link>
                                </li>
                                }
                                {(BASE_CLASS === "footer") && <li className={`${BASE_CLASS}__nav-links__link 
                                            cursor-pointer hover:text-[#ebe793] 
                                            hover:text-shadow transition delay-150 
                                            duration-200 ease-in-out
                                       
                                            `}>
                                    <Link href="/terms-and-conditions">Terms of Use</Link>
                                </li>}

                            </ul>
                        </div>

                    </div>
                </div>
                <div className=' px-10 lg:px-0'>
                    <Devider />
                </div>
                <div className='flex flex-col gap-[32px]    lg:flex-row justify-between text-[#FFEDCA]'>

                    <div className={` ${noto_sans.className} flex flex-col gap-[0.5rem] justify-center items-center  lg:items-start text-[#B7C988]`}>
                        <p className={` text-center px-10 md:px-0 lg:text-left `}>
                            OIT Innovations Private Limited <br />
                            Hridhay, # 34 & 35, 1st Main Road, Raju Layout <br />
                            Near New Horizon School, Nagadevanhalli , Jnanabharathi Post <br />
                            Bangalore: 560056
                        </p>
                        <p> feedback@dharmayana.in</p>
                    </div>
                    <div className='flex flex-col  justify-between gap-8 lg-gap-0 items-center xl:justify-between lg:items-end   '>
                        <div className={`${BASE_CLASS}__footer__social-media`}>
                            <ul className={`${BASE_CLASS}__footer__social-media-links flex gap-5`}>
                                <li className={`${BASE_CLASS}__footer__nav-links__link`}>
                                    <Link href={" https://twitter.com/Dharmayana_in"} title='Follow us on Twitter' target='blank'>
                                        <Image style={{ width: 'auto', height: 'auto' }}
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/TwitterX.svg`} loading='lazy'
                                            alt="Follow us on Twitter" width={30} height={30}></Image>
                                    </Link>
                                </li>
                                <li className={`${BASE_CLASS}__footer__nav-links__link`}>
                                    <Link href="https://www.instagram.com/dharmayana_app/" title="Follow us on Instagram" target='blank'>
                                        <Image style={{ width: 'auto', height: 'auto' }}
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/Instagram.svg`}
                                            alt="Follow us on Instagram" width={30} height={30} loading='lazy' ></Image>
                                    </Link>
                                </li>
                                <li className={`${BASE_CLASS}__footer__nav-links__link`}>
                                    <Link href="https://www.linkedin.com/company/oit-innovations-pvt-ltd/" title='Follow us on Linkedin' target='blank'>
                                        <Image style={{ width: 'auto', height: 'auto' }}
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/LinkedIn.svg`}
                                            alt="Follow us on Linkedin" width={30} height={30} loading='lazy'></Image>
                                    </Link>
                                </li>
                                <li className={`${BASE_CLASS}__footer__nav-links__link`}>
                                    <Link href={'https://youtube.com/@Dharmayana_in'} title='Subscribe to our YouTube Channel' target='blank'>
                                        <Image style={{ width: 'auto', height: 'auto' }}
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/YouTube.svg`}
                                            alt="Subscribe to our YouTube Channel" width={30} height={30} loading='lazy'></Image>
                                    </Link>
                                </li>
                                <li className={`${BASE_CLASS}__footer__nav-links__link`}>
                                    <Link href="https://www.facebook.com/profile.php?id=61567306302222" title='Follow our Facebook Page' target='blank'>
                                        <Image style={{ width: 'auto', height: 'auto' }}
                                            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/Facebook.svg`}
                                            alt="Follow our Facebook Page" width={30} height={30} loading='lazy' ></Image>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className='flex flex-col gap-[4px] items-center lg:items-end'>
                            <p>Made in &#x1f1ee;&#x1f1f3; with ❤️</p>
                            <p>© 2024 OIT Innovations Private Limited</p>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer